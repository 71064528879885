// Beliggenhet
export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'post__header',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              aspectRatio: '21/9',
              class: 'subpage-hero',
            },
            data: this.media.cover,
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: 'post__custom-section',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__content',
            },
            data: {
              content: this.content,
            },
          },
        ],
      },
    },
    {
      component: 'Section',
      props: {
        class: 'post__custom',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              gap: '3rem 0rem',
              class: 'post__features',
            },
            blocks: {
              default: this.customFields.features.map((item, index) => {
                return {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0rem',
                    class: 'post__features-areas',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Image',
                        data: {
                          image: item.icon,
                        },
                        props: {
                          class: '',
                        },
                      },
                      {
                        component: 'Text',
                        data: {
                          content: item.title,
                        },

                        props: {
                          class: 'post__features-title',
                        },
                      },
                      {
                        component: 'Text',
                        data: {
                          content: item.subtitle,
                        },

                        props: {
                          class: 'post__features-subtitle',
                        },
                      },
                    ],
                  },
                }
              }),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'small',
        class: 'post__section',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__content',
            },
            data: {
              content: this.customFields.content,
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: 'section',
        width: 'small',
      },
      blocks: {
        default: {
          component: 'Grid',
          blocks: {
            default: this.customFields.section.map((item, index) => {
              return {
                component: 'Section',
                props: {
                  class: 'post__additional-info',
                },
                blocks: {
                  default: [
                    {
                      component: 'Image',
                      data: {
                        image: item.image,
                      },
                      props: {
                        class: 'post__additional-info-content',
                      },
                    },
                    {
                      component: 'Text',
                      data: {
                        content: item.content,
                      },
                      props: {
                        class: 'additional-info__content',
                      },
                    },
                  ],
                },
              }
            }),
          },
        },
      },
    },

    {
      component: 'Section',
      props: {
        width: 'small',
        class: 'post__cta',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__cta-text',
            },
            data: {
              content: this.customFields.callToAction,
            },
          },
        ],
      },
    },
  ]
}
