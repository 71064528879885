// Beliggenhet
export default function () {
  return [
    {
      component: 'Section',
      props: {
        class: 'post__map-image',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__news-title',
            },
            data: {
              content: this.title,
            },
          },
          {
            // map image
            component: 'Image',
            data: {
              image: this.customFields.image,
            },
            props: {
              class: 'post__news-image',
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: 'post__news',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__news-content',
            },
            data: {
              content: this.content,
            },
          },
        ],
      },
    },

    // Vimeo section
    {
      component: 'Section',
      props: {
        class: 'post__news',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Media',
            props: {
              class: 'post__news-media',
            },
            data: this.customFields.media,
          },
        ],
      },
    },
  ]
}
