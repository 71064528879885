// Beliggenhet
export default function() {
  return [
    // Hero section
    {
      component: 'Section',
      props: {
        width: 'full',
        class: 'post__header',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              aspectRatio: '21/9',
              class: 'subpage-hero',
            },
            data: this.media.cover,
          },
        ],
      },
    },

    // Main content section
    {
      component: 'Section',
      props: {
        class: 'post__location-section',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__content',
            },
            data: {
              content: this.content,
            },
          },
        ],
      },
    },

    // Key info section
    {
      component: 'Section',
      props: {
        class: 'post__location',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__content post__content-location',
            },
            data: {
              content: this.customFields.communication,
            },
          },

          {
            component: 'Flex',
            props: {
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              gap: '3rem 0rem',
              class: 'post__features',
            },
            blocks: {
              default: this.customFields.features.map((item, index) => {
                return {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '0rem',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Image',
                        data: {
                          image: item.icon,
                        },
                        props: {
                          class: '',
                        },
                      },
                      {
                        component: 'Text',
                        data: {
                          content: item.title,
                        },

                        props: {
                          class: 'post__features-title',
                        },
                      },
                      {
                        component: 'Text',
                        data: {
                          content: item.subtitle,
                        },

                        props: {
                          class: 'post__features-subtitle',
                        },
                      },
                    ],
                  },
                }
              }),
            },
          },
        ],
      },
    },

    // Vimeo section
    {
      component: 'Section',
      props: {
        class: '',
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Media',
            props: {
              class: '',
            },
            data: this.customFields.media,
          },
        ],
      },
    },

    // Additional info sections
    {
      component: 'Section',
      props: {
        width: 'small',
      },
      blocks: {
        default: {
          component: 'Grid',
          blocks: {
            default: this.customFields.area.map((item, index) => {
              return {
                component: 'Section',
                props: {
                  class: 'post__additional-info',
                },
                blocks: {
                  default: [
                    {
                      component: 'Image',
                      data: {
                        image: item.image,
                      },
                      props: {
                        class: 'post__additional-info-img',
                      },
                    },
                    {
                      component: 'Text',
                      data: {
                        content: item.content,
                      },
                      props: {
                        class: 'post__additional-info-content',
                      },
                    },
                  ],
                },
              }
            }),
          },
        },
      },
    },

    // Map image
    {
      component: 'Section',
      props: {
        class: 'post__map-image',
        width: 'small',
      },
      blocks: {
        default: [
          {
            // map image
            component: 'Image',
            data: {
              image: this.customFields['map-image'],
            },
            props: {
              class: 'post__image',
            },
          },
        ],
      },
    },

    // Map embed
    {
      component: 'Section',
      props: {
        width: 'small',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            data: {
              coordinates: this.customFields['coordinates'].split(','),
              formatted: 'Orhusveien 10, 3070 Sande i Vestfold',
            },
          },
        ],
      },
    },
  ]
}
