export default function() {
  return [
    {
      component: 'Section',
      props: {
        width: 'small',
        class: 'forbehold__header',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            data: {
              content: this.content,
            },
            props: {
              class: 'post__content',
            },
          },
        ],
      },
    },
  ]
}
