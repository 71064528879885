<template>
  <Loader :value="promises.fetch" class="post">
    <component v-if="component" :is="component" :value="post" :key="post.id" />
  </Loader>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Types from './Types'

export default {
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Post', ['promises', 'item']),

    post() {
      if (!this.item.posts) return {}
      let post = this.item.posts.find(p => p.slug === this.slug)

      if (!post.customFields['call-to-action']) {
        post.customFields['call-to-action'] = this.item.customFields['call-to-action']
      }

      return post
    },

    component() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || []
      let key = sources[0] || ''

      switch (key) {
        case 'location':
          return Types.Location
        case 'plot':
          return Types.Plot
        case 'custom':
          return Types.Custom
        case 'environment':
          return Types.Environment
        case 'developers':
          return Types.Developers
        case 'news':
          return Types.News
        case 'disclaimer':
          return Types.Disclaimer
        default:
          return Types.Article
      }
    },
  },
  methods: {
    ...mapActions('Post', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    ...Types,
  },
}
</script>
