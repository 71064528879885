// Beliggenhet
export default function() {
  return [
    {
      condition: this.media.cover && this.media.cover.length,
      component: 'Section',
      props: {
        width: 'full',
        class: 'post__header',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              aspectRatio: '21/9',
              class: 'subpage-hero',
            },
            data: this.media.cover,
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: 'post__developers-section',
        width: 'full',
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              class: 'post__content',
            },
            data: {
              content: this.content,
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        class: 'post__logos',
        width: 'full',
      },
      blocks: {
        default: [
          {
            component: 'Flex',
            props: {
              flexWrap: 'wrap',
              gap: '3rem 2rem',
              justifyContent: 'center',
            },
            blocks: {
              default: this.customFields.logos.map((item, index) => {
                return {
                  component: 'Link',
                  data: {
                    href: item.description,
                  },
                  blocks: {
                    default: {
                      component: 'Image',
                      data: {
                        image: item,
                      },
                    },
                  },
                }
              }),
            },
          },
        ],
      },
    },
  ]
}
