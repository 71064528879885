<template>
  <Blocks :blocks="config" :customComponents="customComponents" class="disclaimer"></Blocks>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import config from '@/config/consent/disclaimer.js'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this.value)
    },
    customComponents() {
      return {}
    },
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.disclaimer {
  .post__content {
    p:first-of-type {
      font-size: initial;
    }
    h1 {
      text-align: left;
    }
  }
}
</style>
