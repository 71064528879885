<template>
  <Blocks :blocks="config" :customComponents="customComponents"></Blocks>
</template>

<script>
import { Blocks } from '@kvass/pagebuilder'
import Hero from '@/components/Hero'
import config from '@/config/subpages/environment.js'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    config() {
      return config.call(this.value)
    },
    customComponents() {
      return { Hero }
    },
  },
  components: {
    Hero,

    Blocks,
  },
}
</script>

<style lang="scss"></style>
